import { Route, Routes, useLocation } from "react-router-dom"
import Home from "../components/home"
import AddBlog from "../components/Blogs/addBlog";
import BlogListing from "../components/Blogs/blogListing";
import AddCategory from "../components/Categories/addCategory";
import EditBlog from "../components/Blogs/editBlog";
import AddCountry from "../components/Countries/addCountry";
import CountryList from "../components/Countries/countryList";
import CategoryList from "../components/Categories/categoryList";
import EditCountry from "../components/Countries/editCountry";
import Login from "../components/login";
import { useContext } from "react";
import GlobalContext from "../context/GlobalContext";


const Routing = () => {
   const global=useContext(GlobalContext)
    return (
        <>
            <Routes>
               
                {(global.token !== '' && global.token !== null) ?
                    <>
                        <Route path="/" element={<Home />} />
                        <Route path="/add-blog" element={<AddBlog />} />
                        <Route path="/blog/:slug" element={<EditBlog />} />
                        <Route path="/add-cat" element={<AddCategory />} />
                        <Route path="/blogs" element={<BlogListing />} />
                        <Route path="/add-country" element={<AddCountry />} />
                        <Route path="/country/:id" element={<EditCountry />} />
                        <Route path="/countries" element={<CountryList />} />
                        <Route path="/categories" element={<CategoryList />} />
                    </>
                    :
                    <Route path="*" element={<Login />} />
                }
            </Routes>
        </>
    )
}

export default Routing;