import { useContext, useEffect } from "react";
import Sidebar from "./sidebar";
import GlobalContext from "../context/GlobalContext";

const Layout = ({ children }) => {
  let global =useContext(GlobalContext)
    return (
        <>
            {(global.token !== '' && global.token !== null) &&
                <Sidebar />
            }
            {children}
        </>
    )
}

export default Layout;