import logo from './logo.svg';
// import './App.css';
import Layout from './layout/layout';
import Routing from './routes/routing';
import { useEffect } from 'react';

function App() {
 
  return (
    <div className='main-admin'>
      <Layout>
        <Routing/>
      </Layout>
    </div>
  );
}

export default App;
