import { useEffect, useState } from "react";
import GlobalContext from "./GlobalContext"

const GlobalState=(props)=>{
    
    const[token, setToken]=useState('')
    
    
    useEffect(() => {
        if(localStorage.getItem('token') != null && localStorage.getItem('token') != "") {
           
            setToken(localStorage.getItem('token'));
           
        }
    }, []);
    
    const value={
        
        token, setToken,
       
    }
    
    return (
        <>
        <GlobalContext.Provider value={value}>
            {props.children}
        </GlobalContext.Provider>
        </>
    )
}

export default  GlobalState;