import { useEffect, useState } from "react"
import { deleteCountry, getCountries } from "../../services/apiService"
import { useNavigate } from "react-router-dom"
import {BsTrash3} from 'react-icons/bs'
import { FaEdit } from "react-icons/fa"
const CountryList = () => {
    const [countries,setCountries]=useState([])
    let navigate= useNavigate()
    const getCountryList=()=>{
        getCountries().then(response=>{
            if(response.status===200){
                setCountries(response.data.data)
            }
        }).catch(err=>{
            console.log(err)
        })
    }

    const delCountry = (e,id) =>{
        deleteCountry({id:id}).then(response=>{
            if(response.status === 200 ){
               let arr=[...countries]
               let newArr=arr.filter((item)=>item._id!==response.data.data._id)
               setCountries(newArr)
            }
        }).catch(err=>{
            console.log(err)
        })
    }

    useEffect(()=>{
        getCountryList()
    },[])

    return (
        <>

            <div class="right-admin">
                <div class="outer-right-admin">

                    <div class="top-bar">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="top-bar-left">
                                    <div class="form-group">
                                        {/* <span><img src="assets/images/search.png" alt="" /></span> */}
                                        {/* <input type="text" placeholder="Search…" alt="" /> */}
                                    </div>
                                </div>
                            </div>
                            {/* <div class="col-md-6">
                                <div class="top-bar-right">
                                    <div>
                                        <h5>Fletch Skinner</h5>
                                        <p>Administrator</p>
                                    </div>
                                    <span><img src="assets/images/user.png" alt="" /></span>
                                </div>
                            </div> */}
                        </div>
                    </div>
                    <div class="inner-scroll firld-from">
                        <div class="filter-button">
                            <div class="title">
                                <h4>Entry List</h4>
                                {/* <ul>
                                    <li><a href="#">Home</a></li>
                                    <li><a href="#">Blog List</a></li>
                                </ul> */}
                            </div>
                            <div class="filter-bn">
                                {/* <a href="#"><img src="assets/images/filter.png" /> Filter</a> */}
                                <button onClick={() => navigate('/add-country')}>Add Entry</button>
                            </div>
                        </div>
                        <div class="blog-table">
                            <div class="blog-table-filetr">
                                <div class="left-filter">
                                    <div class="form-group">
                                        {/* <input type="text" placeholder="Search..." /> */}
                                    </div>
                                </div>
                                {/* <div class="right-filter">
                                    <div class="form-group">
                                        <span>1 Selected</span>
                                        <button class="red">Delete</button>
                                        <button>Archive</button>
                                        <button>Publish</button>
                                        <button>Unpublish</button>
                                    </div>
                                </div> */}
                            </div>
                            <table>
                                <tr>
                                    {/* <th><label class="chk">
                                        <input type="checkbox" />
                                        <span class="checkmark"></span>
                                    </label></th> */}
                                    <th>COUNTRY</th>
                                    <th>PROFESSION</th>
                                    <th>INCOME</th>
                                    <th>YEAR</th>
                                    {/* <th>COMMENTS</th> */}
                                    <th>ACTIONS</th>
                                </tr>
                                {countries && countries.map((country) =>
                                    <tr>
                                        {/* <td><label class="chk">
                                            <input type="checkbox" value={country._id}/>
                                            <span class="checkmark"></span>
                                        </label></td> */}
                                        <td class="clr-black"><img src="assets/images/user.png" alt="" />{country.country}</td>
                                        <td>{country.designation}</td>
                                        <td class="clr-black">${country.income}</td>
                                        <td><span class="pub">{country.year}</span></td>
                                        {/* <td>36</td> */}
                                        <td>
                                            <FaEdit onClick={() => navigate(`/country/${country._id}`)} />
                                            <span style={{ marginLeft: "10px" }} onClick={(e) =>delCountry(e,country._id)}><BsTrash3/></span>
                                        </td>
                                    </tr>
                                )}
                                {/* <tr>
                                    <td><label class="chk">
                                        <input type="checkbox" />
                                        <span class="checkmark"></span>
                                    </label></td>
                                    <td class="clr-black"><img src="assets/images/user.png" alt="" />Tips & Tricks on Upgrading Node.js</td>
                                    <td>JavaScript</td>
                                    <td class="clr-black">12.2K</td>
                                    <td><span class="pub">Published</span></td>
                                    <td>36</td>
                                    <td><select>
                                        <option>Actions</option>
                                        <option>Actions</option>
                                        <option>Actions</option>
                                    </select></td>
                                </tr>
                                <tr>
                                    <td><label class="chk">
                                        <input type="checkbox" />
                                        <span class="checkmark"></span>
                                    </label></td>
                                    <td class="clr-black"><img src="assets/images/user.png" alt="" />Tips & Tricks on Upgrading Node.js</td>
                                    <td>JavaScript</td>
                                    <td class="clr-black">12.2K</td>
                                    <td><span class="pub arc">Archive</span></td>
                                    <td>36</td>
                                    <td><select>
                                        <option>Actions</option>
                                        <option>Actions</option>
                                        <option>Actions</option>
                                    </select></td>
                                </tr>
                                <tr>
                                    <td><label class="chk">
                                        <input type="checkbox" />
                                        <span class="checkmark"></span>
                                    </label></td>
                                    <td class="clr-black"><img src="assets/images/user.png" alt="" />Tips & Tricks on Upgrading Node.js</td>
                                    <td>JavaScript</td>
                                    <td class="clr-black">12.2K</td>
                                    <td><span class="pub unbu">Unpblish</span></td>
                                    <td>36</td>
                                    <td><select>
                                        <option>Actions</option>
                                        <option>Actions</option>
                                        <option>Actions</option>
                                    </select></td>
                                </tr> */}
                            </table>
                            {/* <div class="add-pagination">
                                <p><span>Showing:</span> 14 of 20</p>
                                <ul>
                                    <li><a href="#"> <i class="fas fa-chevron-left"></i> </a></li>
                                    <li><a href="#"> 1   </a></li>
                                    <li class="active"><a href="#"> 2   </a></li>
                                    <li><a href="#"> 3   </a></li>
                                    <li><a href="#"> 4   </a></li>
                                    <li><a href="#"> 5   </a></li>
                                    <li><a href="#"> 6   </a></li>
                                    <li><a href="#"> <i class="fas fa-chevron-right"></i>  </a></li>
                                </ul>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CountryList;