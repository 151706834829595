import { useEffect } from "react"
import { addCategory } from "../../services/apiService"
import { useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"

const AddCategory = () => {
    const { register, formState: { errors }, handleSubmit } = useForm()
    let navigate= useNavigate()
    const onSubmission=(data)=>{
        addCategory(data).then(response=>{
            if(response.status===201){
                alert("category added")
                navigate('/categories')
            }
        }).catch(err=>{
            console.log(err);
        })
    }

  
    return (
        <>
            <div class="right-admin">
                <div class="outer-right-admin">

                    <div class="top-bar">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="top-bar-left">
                                    <div class="form-group">
                                        <span><img src="assets/images/search.png" alt="" /></span>
                                        <input type="text" placeholder="Search…" alt="" />
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="top-bar-right">
                                    <div>
                                        <h5>Fletch Skinner</h5>
                                        <p>Administrator</p>
                                    </div>
                                    <span><img src="assets/images/user.png" alt="" /></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="inner-scroll firld-from">
                        <div class="title">
                            <h4>Category</h4>
                           
                        </div>
                        <div class="inner-form">
                            <h6>Add Category </h6>
                            <form onSubmit={handleSubmit(onSubmission)}>
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label>Name</label>
                                            <input type="text" placeholder="" {...register("name",{required:"Field is required."})} />
                                        </div>
                                    </div>
                                    {errors.name && <span>{errors.name.message}</span>}
                                </div>
                                <div class="save-btn"><button type="submit" class="save">Submit</button></div>
                                
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddCategory;