import { useEffect, useState } from "react"
import BlogEditor from "./blogEdit"
import ReactQuill from "react-quill"
import { formats, modules } from "./blogToolOptions"
import { useNavigate, useParams } from "react-router-dom"

const { useForm, Controller } = require("react-hook-form")
const { getBlogDetails, getCategories, addBlog, editBlog } = require("../../services/apiService")

const EditBlog = () => {
    const [categories, setCategories] = useState([])
    const { register, formState: { errors }, handleSubmit, control,setValue} = useForm()
    const {slug}=useParams()
    const[blogId,setBlogId]=useState('')
    let navigate=useNavigate()

    const blogDetails=()=>{
        getBlogDetails(slug).then(response=>{
            if(response.status===200){
                console.log(response.data.data);
                setBlogId(response.data.data._id)
                setValue('title', response.data.data.title)
                setValue('slug', response.data.data.slug)
                setValue('category', response.data.data.category)
                setValue('visibility', response.data.data.visibility)
                setValue('description', response.data.data.description)
                // setValue('image', response.data.data.image[0])
                setValue('metaTitle', response.data.data.metaTitle)
                setValue('metaDesc', response.data.data.metaDesc)
        
            }
        })
    }
    const Categories = () => {
        getCategories().then(response => {
            if (response.status === 200) {
                setCategories(response.data.data)
                blogDetails()
            }
        }).catch(err => {
            console.error(err);
        })
    }

    const onSubmission = (data) => {

        data['id']=blogId


        editBlog(data).then(response => {
            if (response.status === 200) {
                alert("Blog edited Successfully")
                navigate('/blogs')
            }
        }).catch(err => {
            console.log(err)
        })
    }


    useEffect(() => {
        Categories()
        
    }, [])
    return (
        <>
            <div class="right-admin">
                <div class="outer-right-admin">

                    <div class="top-bar">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="top-bar-left">
                                    <div class="form-group">
                                        <span><img src={require("../../assets/images/search.png")} alt="" /></span>
                                        <input type="text" placeholder="Search…" alt="" />
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="top-bar-right">
                                    <div>
                                        <h5>Fletch Skinner</h5>
                                        <p>Administrator</p>
                                    </div>
                                    <span><img src={require("../../assets/images/user.png")} alt="" /></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="inner-scroll firld-from">
                        <div class="title">
                            <h4>Edit Blog</h4>
                            {/* <ul>
                                <li><a href="#">Home</a></li>
                                <li><a href="#">Blog List</a></li>
                                <li><a href="#">Add Blog</a></li>
                            </ul> */}
                        </div>
                        <div class="inner-form">
                            <h6>General</h6>
                            <form onSubmit={handleSubmit(onSubmission)}>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label>Post Title</label>
                                            <input type="text" placeholder="" {...register("title", { required: "Field is required." })} />
                                        </div>
                                        {errors.title && <span>{errors.title.message}</span>}
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label>URL Slug</label>
                                            <input type="text" placeholder="" {...register('slug', { required: "Field is required." })} />
                                        </div>
                                        {errors.slug && <span>{errors.slug.message}</span>}
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label>Category</label>
                                            <select {...register("category", { required: "Select one of the options." })}>
                                                <option value=''>Choose</option>
                                                {categories && categories.map((category) =>
                                                    <option value={category._id}>{category.name}</option>
                                                )}
                                            </select>
                                        </div>
                                        {errors.category && <span>{errors.category.message}</span>}
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label>Visibility</label>
                                            <select {...register("visibility", { required: "Select one of the options." })}>
                                                <option value="">choose</option>
                                                <option value="Public">Public</option>
                                                <option value="Private">Private</option>

                                            </select>
                                        </div>
                                        {errors.visibility && <span>{errors.visibility.message}</span>}
                                    </div>
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label>Description</label>
                                            {/* <img style={{width:"100%"}} src={require("../../assets/images/Editor.png")} alt="" /> */}
                                            <Controller
                                                name="description"
                                                rules={{ required: "Field is required." }}
                                                control={control}
                                                render={(({ field: { onChange, value }, fieldState: { error } }) =>
                                                    <>
                                                        <BlogEditor />
                                                        <ReactQuill
                                                            theme="snow"
                                                            value={value}
                                                            onChange={(val) => val === "<p><br></p>" ? onChange('') : onChange(val)}
                                                            placeholder={"Enter Default Terms..."}
                                                            modules={modules}
                                                            formats={formats}
                                                        />
                                                        {error?.message && <span>{error.message}</span>}
                                                    </>
                                                )} />
                                        </div>

                                    </div>
                                </div>
                                {/* <button type="submit">Submit</button> */}

                                <div class="blog-add">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="inner-blog-add">
                                                <h5>Meta Options</h5>
                                                <form>
                                                    <div class="form-group">
                                                        <label>Page Title</label>
                                                        <input type="text" placeholder="" {...register("metaTitle", { required: "Meta title is required." })} />
                                                        {errors.metaTitle && <span>{errors.metaTitle.message}</span>}
                                                    </div>
                                                    <div class="form-group">
                                                        <label>Description</label>
                                                        {/* <img style={{ width: "100%" }} src={require("../../assets/images/Editor.png")} alt="" /> */}
                                                        <input type="text" placeholder="" {...register("metaDesc", { required: "Meta description is required." })} />
                                                        {errors.metaDesc && <span>{errors.metaDescription.message}</span>}
                                                    </div>
                                                    {/* <div class="form-group">
                                                        <label>Keywords</label>
                                                        <input type="text" placeholder="" />
                                                    </div> */}
                                                </form>
                                            </div>
                                        </div>
                                        {/* <div class="col-md-6">
                                            <div class="inner-blog-add">

                                                <form>
                                                    <div class="form-group media-upload">
                                                        <label>Media</label>
                                                        <span><img src={require("../../assets/images/media.png")} alt="" />
                                                            <h6>Drag & Drop</h6>
                                                            <label>OR</label>
                                                            <h3>Browse Photo</h3>
                                                            <p>Supports: *.png, *.jpg and *.jpeg</p>
                                                        </span>
                                                        <input type="file" placeholder="" {...register("image", { required: "Image is required." })} />
                                                        {errors.image && <span>{errors.image.message}</span>}
                                                    </div>

                                                </form>
                                            </div>
                                        </div> */}
                                        

                                    </div>
                                </div>

                                <div class="save-btn">
                                    {/* <button>Discard</button> */}
                                    <button type="submit" class="save">Save Changes</button>
                                </div>
                            </form>

                        </div>



                    </div>
                </div>
            </div>
        </>
    )
}

export default EditBlog