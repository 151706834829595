import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import { formats, modules } from "./blogToolOptions";
import { useEffect, useState } from "react";
import BlogEditor from "./blogEdit";
import { Controller, useForm } from "react-hook-form";
import { addBlog, getCategories } from "../../services/apiService";
import { useNavigate } from "react-router-dom";

const AddBlog = () => {
    const [categories, setCategories] = useState([])
    const { register, formState: { errors }, handleSubmit, control,watch } = useForm()
    const imageFile = watch('image'); // Watch the 'image' field
    let slug=watch('title')?.replace(/[?\s.]+/g, '-')
    let navigate= useNavigate()
    const onSubmission = (data) => {
        const formData = new FormData()
        formData.append('title', data.title)
        formData.append('slug', slug)
        formData.append('category', data.category)
        formData.append('visibility', data.visibility)
        formData.append('description', data.description)
        formData.append('image', data.image[0])
        formData.append('metaTitle', data.metaTitle)
        formData.append('metaDescription', data.metaDescription)

        addBlog(formData).then(response => {
            if (response.status === 201) {
                alert("Blog Added Successfully")
                navigate('/blogs')
            }
        }).catch(err => {
            console.log(err)
        })
    }

    const Categories = () => {
        getCategories().then(response => {
            if (response.status === 200) {
                setCategories(response.data.data)
            }
        }).catch(err => {
            console.error(err);
        })
    }

    useEffect(() => {
        Categories()
    }, [])
    return (
        <>
            <div class="right-admin">
                <div class="outer-right-admin">

                    <div class="top-bar">
                    <div className="toggle-btn">
                    <span><i class="fas fa-bars"></i></span>
                </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="top-bar-left">
                                    <div class="form-group">
                                        <span><img src={require("../../assets/images/search.png")} alt="" /></span>
                                        <input type="text" placeholder="Search…" alt="" />
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="top-bar-right">
                                    <div>
                                        <h5>Fletch Skinner</h5>
                                        <p>Administrator</p>
                                    </div>
                                    <span><img src={require("../../assets/images/user.png")} alt="" /></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="inner-scroll firld-from">
                        <div class="title">
                            <h4>Add Blog</h4>
                            {/* <ul>
                                <li><a href="#">Home</a></li>
                                <li><a href="#">Blog List</a></li>
                                <li><a href="#">Add Blog</a></li>
                            </ul> */}
                        </div>
                        <div class="inner-form">
                            <h6>General</h6>
                            <form onSubmit={handleSubmit(onSubmission)}>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label>Post Title</label>
                                            <input type="text" placeholder="" {...register("title", { required: "Field is required." })} />
                                        </div>
                                        {errors.title && <span>{errors.title.message}</span>}
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label>URL Slug</label>
                                            <input type="text" value={slug} disabled  placeholder="" />
                                        </div>
                                        {/* {errors.slug && <span>{errors.slug.message}</span>} */}
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label>Category</label>
                                            <select {...register("category", { required: "Select one of the options." })}>
                                                <option value=''>Choose</option>
                                                {categories && categories.map((category) =>
                                                    <option value={category._id}>{category.name}</option>
                                                )}
                                            </select>
                                        </div>
                                        {errors.category && <span>{errors.category.message}</span>}
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label>Visibility</label>
                                            <select {...register("visibility", { required: "Select one of the options." })}>
                                                <option value="">choose</option>
                                                <option value="Public">Public</option>
                                                <option value="Private">Unlisted</option>

                                            </select>
                                        </div>
                                        {errors.visibility && <span>{errors.visibility.message}</span>}
                                    </div>
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label>Description</label>
                                            {/* <img style={{width:"100%"}} src={require("../../assets/images/Editor.png")} alt="" /> */}
                                            <Controller
                                                name="description"
                                                rules={{ required: "Field is required." }}
                                                control={control}
                                                render={(({ field: { onChange, value }, fieldState: { error } }) =>
                                                    <>
                                                        <BlogEditor />
                                                        <ReactQuill
                                                            theme="snow"
                                                            value={value}
                                                            onChange={(val) => val === "<p><br></p>" ? onChange('') : onChange(val)}
                                                            placeholder={"Enter Default Terms..."}
                                                            modules={modules}
                                                            formats={formats}
                                                        />
                                                        {error?.message && <span>{error.message}</span>}
                                                    </>
                                                )} />
                                        </div>

                                    </div>
                                </div>
                                {/* <button type="submit">Submit</button> */}

                                <div class="blog-add">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="inner-blog-add">
                                                <h5>Meta Options</h5>
                                                <form>
                                                    <div class="form-group">
                                                        <label>Page Title</label>
                                                        <input type="text" placeholder="" {...register("metaTitle", { required: "Meta title is required." })} />
                                                        {errors.metaTitle && <span>{errors.metaTitle.message}</span>}
                                                    </div>
                                                    <div class="form-group">
                                                        <label>Description</label>
                                                        {/* <img style={{ width: "100%" }} src={require("../../assets/images/Editor.png")} alt="" /> */}
                                                        <input type="text" placeholder="" {...register("metaDescription", { required: "Meta title is required." })} />
                                                        {errors.metaDescription && <span>{errors.metaDescription.message}</span>}
                                                    </div>
                                                    <div class="form-group">
                                                        <label>Keywords</label>
                                                        <input type="text" placeholder="" />
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="inner-blog-add">

                                                <form>
                                                    <div class="form-group media-upload">
                                                        <label>Media</label>
                                                        <span><div><img src={(imageFile && imageFile[0] ?URL.createObjectURL(imageFile[0]) :require("../../assets/images/media.png"))} alt="" /></div>
                                                            <h6>Drag & Drop</h6>
                                                            <label>OR</label>
                                                            <h3>Browse Photo</h3>
                                                            <p>Supports: *.png, *.jpg and *.jpeg</p>
                                                        </span>
                                                        <input type="file" placeholder="" {...register("image", { required: "Image is required." })} />
                                                        {errors.image && <span>{errors.image.message}</span>}
                                                    </div>

                                                </form>
                                            </div>
                                        </div>
                                        {/* <div class="col-md-4">
                                            <div class="inner-blog-add checks">
                                                <h5>Settings</h5>
                                                <form>
                                                    <div class="form-group">
                                                        <label>Status</label>
                                                        <select>
                                                            <option>Public</option>
                                                            <option>Public</option>
                                                            <option>Choose</option>
                                                        </select>
                                                    </div>
                                                    <div class="form-group">
                                                        <label>Template</label>
                                                        <select>
                                                            <option>Public</option>
                                                            <option>Public</option>
                                                            <option>Choose</option>
                                                        </select>
                                                    </div>
                                                    <div class="form-group">
                                                        <label>Template</label>
                                                        <select>
                                                            <option>Public</option>
                                                            <option>Public</option>
                                                            <option>Choose</option>
                                                        </select>
                                                    </div>
                                                    <div class="form-group">
                                                        <label>Other</label>
                                                        <div class="form-group swith-btn">
                                                            <label class="switch">
                                                                <input type="checkbox" />
                                                                <span class="slider round"></span>
                                                            </label>
                                                            <p>Allow comments</p>
                                                        </div>

                                                        <div class="form-group swith-btn">
                                                            <label class="switch">
                                                                <input type="checkbox" />
                                                                <span class="slider round"></span>
                                                            </label>
                                                            <p>Allow pingbacks & trackbacks</p>
                                                        </div>

                                                    </div>
                                                </form>
                                            </div>
                                        </div> */}

                                    </div>
                                </div>

                                <div class="save-btn">
                                    <button onClick={()=>navigate('/blogs')}>Discard</button>
                                    <button type="submit" class="save">Publish</button>
                                </div>
                            </form>

                        </div>



                    </div>
                </div>
            </div>
        </>
    )
}

export default AddBlog;