import { useContext } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import GlobalContext from "../context/GlobalContext";

const Sidebar = () => {
    let global=useContext(GlobalContext)
    let navigate=useNavigate()
    const handleLoggingOut=()=>{
        localStorage.removeItem('token');
        global.setToken('')
        navigate('/')
    }
    return (
        <>
            <div class="left-admin">
                <div class="logo">
                    <Link to="/"><img className="logo-img" src={require('../assets/images/newone.png')}/></Link>
                </div>
                
                <span>Menu</span>
                <ul>
                    <li><NavLink to="/"  ><i class="fas fa-home"></i>Dashboard</NavLink></li>
                    <li><NavLink to="/blogs" ><i class="far fa-address-card"></i>Blogs</NavLink></li>
                    <li><NavLink to="/categories" ><i class="far fa-hdd"></i>Categories</NavLink></li>
                    <li><NavLink to="/countries"><i class="far fa-calendar"></i>Entries</NavLink></li>
                    {/* <li><a href="#"><i class="fas fa-cog"></i>Settings</a></li> */}

                </ul>
                <div class="logout">
                    <div style={{cursor:"pointer"}} onClick={handleLoggingOut}><i class="fas fa-sign-out-alt"></i>Log Out</div>
                </div>
            </div>
        </>
    )
}

export default Sidebar;