import { useEffect, useState } from "react"
import { AllCountries } from "../services/apiService"
import Chart from 'chart.js/auto';
import { Bar } from "react-chartjs-2"

const Home = () => {
    const [countries, setCountries] = useState([])
    const getCountriesData = () => {
        AllCountries().then(response => {
            if (response.status === 200) {
                setCountries(response.data.data.filter((val,i)=>i<5))
            }
        })
    }
    const option = {

        scales: {
            x: {
                grid: {
                    display: false,
                    // categorySpacing: 0.2, // Adjust the width of the grid columns
                },
            },
            y: {
                ticks: {
                    callback: value => `$ ${value}`,
                }
            }
        },
        plugins: {
            legend: {
                display: false //This will do the task
            },
        },


    }

    const labels = countries?.map((country) => country.country)
    const data = {
        labels: labels,
        datasets: [{
            label: '',
            data: countries?.map((country) => Number(country.income)),
            barPercentage: 0.3,
            backgroundColor: [
                'rgba(255, 99, 132, 0.2)',
                'rgba(255, 159, 64, 0.2)',
                'rgba(255, 205, 86, 0.2)',
                'rgba(75, 192, 192, 0.2)',
                'rgba(54, 162, 235, 0.2)',
                'rgba(153, 102, 255, 0.2)',
                'rgba(201, 203, 207, 0.2)'
            ],
            borderColor: [
                'rgb(255, 99, 132)',
                'rgb(255, 159, 64)',
                'rgb(255, 205, 86)',
                'rgb(75, 192, 192)',
                'rgb(54, 162, 235)',
                'rgb(153, 102, 255)',
                'rgb(201, 203, 207)'
            ],
            borderWidth: 1
        },
        ]
    };

    useEffect(() => {
        getCountriesData()
    }, [])
    return (
        <>
            <div class="right-admin">
                <div class="outer-right-admin">

                    <div class="top-bar">
                        <div className="toggle-btn">
                            <span><i class="fas fa-bars"></i></span>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="top-bar-left">
                                    {/* <div class="form-group">
                                        <span><img src={require("../assets/images/search.png")} alt="" /></span>
                                        <input type="text" placeholder="Search…" alt="" />
                                    </div> */}
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="top-bar-right">
                                    <div>
                                        <h5>Fletch Skinner</h5>
                                        <p>Administrator</p>
                                    </div>
                                    <span><img src={require("../assets/images/user.png")} alt="" /></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="inner-scroll">
                        <div class="row">
                            <div class="col-md-3">
                                <div class="inner-box-shd">
                                    <img src={require("../assets/images/bx-one.png")} alt="" />
                                    <h4>18.356</h4>
                                    <span>Followers</span>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="inner-box-shd">
                                    <img src={require("../assets/images/bx-two.png")} alt="" />
                                    <h4>248</h4>
                                    <span>Posts</span>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="inner-box-shd">
                                    <img src={require("../assets/images/bx-three.png")} alt="" />
                                    <h4>22.627</h4>
                                    <span>Likes</span>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="inner-box-shd">
                                    <img src={require("../assets/images/bx-four.png")} alt="" />
                                    <h4>427k</h4>
                                    <span>Viewers</span>
                                </div>
                            </div>
                        </div>

                        <div class="map-iner">
                            {/* <img src={require("../assets/images/map.png")} alt="" />
                             */}
                            <Bar data={data} options={option} />
                        </div>
                        {/* <div class="row">
                            <div class="col-md-7">
                                <div class="data-table">
                                    <table>
                                        <tr>
                                            <th>Article Title</th>
                                            <th>Post Date</th>
                                            <th>Category</th>
                                            <th>Comment</th>
                                        </tr>
                                        <tr>
                                            <td> <span><img src={require("../assets/images/user.png")} alt="" /></span>Your Rooftop Garden Could Be a Solar</td>
                                            <td>16 Nov 2021</td>
                                            <td><label>Science</label></td>
                                            <td>136 Comments</td>
                                        </tr>
                                        <tr>
                                            <td> <span><img src={require("../assets/images/user.png")} alt="" /></span>Your Rooftop Garden Could Be a Solar</td>
                                            <td>16 Nov 2021</td>
                                            <td><label class="blue">Ideas</label></td>
                                            <td>136 Comments</td>
                                        </tr>
                                        <tr>
                                            <td> <span><img src={require("../assets/images/user.png")} alt="" /></span>Your Rooftop Garden Could Be a Solar</td>
                                            <td>16 Nov 2021</td>
                                            <td><label class="org">Games</label></td>
                                            <td>136 Comments</td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                            <div class="col-md-5">
                                <div class="data-chart">
                                    <h5>USED DEVICE</h5>
                                    <img src={require("../assets/images/pie-chart.png")} alt="" />
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>

        </>
    )
}

export default Home;