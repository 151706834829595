import axios from "axios"

// let apiUrl="http://localhost:5000/api/v1/"
let apiUrl="https://backend.wagedifferentials.org/api/v1/"

export const AdminLogin=(payload)=>{
    return axios.post(apiUrl+'users/signin',payload)
}
export const AllCountries=()=>{
    return axios.get(apiUrl+'country/')
}

export const addCategory=(payload)=>{
    return axios.post(apiUrl+'blogs/add-cat',payload)
}

export const getCategories=()=>{
    return axios.get(apiUrl+'blogs/categories')
}

export const deletecategory=(payload)=>{
    return axios.post(apiUrl+'blogs/delCat',payload)
}

export const addBlog=(payload)=>{
    return axios.post(apiUrl+'blogs/add',payload)
}

export const deleteBlog=(payload)=>{
    return axios.post(apiUrl+'blogs/delete',payload)
}

export const addCountry=(payload)=>{
    return axios.post(apiUrl+'country/add',payload)
}

export const editCountry=(payload)=>{
    return axios.put(apiUrl+'country/edit',payload)
}
export const getCountrybyId=(id)=>{
    return axios.get(apiUrl+'country/'+id)
}

export const deleteCountry=(payload)=>{
    return axios.post(apiUrl+'country/delete',payload)
}

export const getCountries=()=>{
    return axios.get(apiUrl+'country/')
}

export const editBlog=(payload)=>{
    return axios.put(apiUrl+'blogs/edit',payload)
}

export const getBlogs=()=>{
    return axios.get(apiUrl+'blogs/')
}

export const getBlogDetails=(slug)=>{
    return axios.get(apiUrl+'blogs/'+slug)
}
