import { Controller, useForm } from "react-hook-form";
import { editCountry, getCountrybyId } from "../../services/apiService";
import { useNavigate, useParams, } from "react-router-dom";
import moment from "moment/moment";
import { useEffect, useState } from "react";
import BlogEditor from "../Blogs/blogEdit";
import ReactQuill from "react-quill";
import { formats, modules } from "../Blogs/blogToolOptions";

const EditCountry = () => {
    const { register, formState: { errors }, handleSubmit, setValue ,control} = useForm()
    const [countryId,setCountryId]=useState()
    let navigate = useNavigate()
    const {id}=useParams()
    const countryDetails=()=>{
        getCountrybyId(id).then(response=>{
            if(response.status===200){
                console.log(response.data.data);
                setCountryId(response.data.data._id)
                setValue('country', response.data.data.country)
                setValue('year', response.data.data.year)
                setValue('designation', response.data.data.designation)
                setValue('income', response.data.data.income)
                setValue('description', response.data.data.description)
               
        
            }
        })
    }
    const onSubmission = (data) => {
        data['id']=countryId
        editCountry(data).then(response => {
            if (response.status === 200) {
                alert("Country edited sucessfully.")
                navigate('/countries')
            }
        }).catch(err => {
            console.error('Error:', err)
            alert(err.response.data.message)
        })
    }

    useEffect(()=>{
        countryDetails()
    },[])
    return (
        <>
            <div class="right-admin">
                <div class="outer-right-admin">

                    <div class="top-bar">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="top-bar-left">
                                    <div class="form-group">
                                        {/* <span><img src="assets/images/search.png" alt="" /></span>
                                        <input type="text" placeholder="Search…" alt="" /> */}
                                    </div>
                                </div>
                            </div>
                            {/* <div class="col-md-6">
                                <div class="top-bar-right">
                                    <div>
                                        <h5>Fletch Skinner</h5>
                                        <p>Administrator</p>
                                    </div>
                                    <span><img src="assets/images/user.png" alt="" /></span>
                                </div>
                            </div> */}
                        </div>
                    </div>
                    <div class="inner-scroll firld-from">
                        <div class="title">
                            <h4>Edit Entry</h4>

                        </div>
                        <div class="inner-form">
                            <h6>Edit Entry List </h6>
                            <form onSubmit={handleSubmit(onSubmission)}>
                                <div class="row">
                                    {/* <div class="col-md-12">
                                        <div class="form-group">
                                            <label>Add Image</label>
                                            <input type="file" value="" />
                                            <span className="image-pre"><i class="far fa-images"></i>
                                                <p>Upload Image here...</p>
                                            </span>
                                            <div className="image-preview">
                                                <div className="inner-imag-wrap">
                                                    <span><i class="fas fa-times"></i></span>
                                                    <img src={prev} alt="" />
                                                </div>
                                                <div className="inner-imag-wrap">
                                                    <span><i class="fas fa-times"></i></span>
                                                    <img src={prev} alt="" />
                                                </div>

                                            </div>
                                        </div>
                                        {errors.country && <span>{errors.country.message}</span>}
                                    </div> */}
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label>Country</label>
                                            <input type="text" placeholder="" {...register('country', { required: "Field is required." })} />
                                        </div>
                                        {errors.country && <span>{errors.country.message}</span>}
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label>Year</label>
                                            <input type="text" placeholder=""  {...register('year', { required: "Field is required.",validate:v=>Number(v)|| "Please enter valid value." ,maxLength:{value:4,message:"Year should be valid input"}, minLength:{value:4,message:"Year should be valid input"},max:{value:moment().format('YYYY'),message:"Year is not valid"}})} />
                                        </div>
                                        {errors.year && <span>{errors.year.message}</span>}
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label>Profession</label>
                                            <input type="text" placeholder="" {...register('designation', { required: "Field is required." })} />
                                        </div>
                                        {errors.designation && <span>{errors.designation.message}</span>}
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label>Income</label>
                                            <input type="text" placeholder="" {...register('income', { required: "Field is required.",validate:v=>Number(v)|| "Please enter numeric value." })} />
                                        </div>
                                        {errors.income && <span>{errors.income.message}</span>}
                                    </div>

                                    <div class="col-md-12">
                                        {/* <div class="form-group">
                                            <label>Description</label>
                                            <textarea style={{ width: "100%" }} rows={4} placeholder="" {...register('description', { required: "Field is required." })} />
                                        </div>
                                        {errors.description && <span>{errors.description.message}</span>} */}
                                         <Controller
                                                name="description"
                                                rules={{ required: "Field is required." }}
                                                control={control}
                                                render={(({ field: { onChange, value }, fieldState: { error } }) =>
                                                    <>
                                                        <BlogEditor />
                                                        <ReactQuill
                                                            theme="snow"
                                                            value={value}
                                                            onChange={(val) => val === "<p><br></p>" ? onChange('') : onChange(val)}
                                                            placeholder={"Enter Default Terms..."}
                                                            modules={modules}
                                                            formats={formats}
                                                        />
                                                        {error?.message && <span>{error.message}</span>}
                                                    </>
                                                )} />
                                    </div>

                                </div>
                                <div class="save-btn">
                                    {/* <button onClick={()=>navigate('/countries')}>Discard</button> */}
                                    <button type="submit" class="save">Save Changes</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EditCountry;