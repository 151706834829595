import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { AdminLogin } from "../services/apiService";
import { useContext } from "react";
import GlobalContext from "../context/GlobalContext";

const Login=()=>{
    let {register,handleSubmit,formState:{errors}}=useForm()
    let global=useContext(GlobalContext)
	let navigate= useNavigate()
    function signin(data){
       AdminLogin(data).then(res=>{
        if(res.status==200){
            global.setToken(res.data.token)
            localStorage.setItem("token",res.data.token)
            navigate('/')
        }
       })
    }
    return (
        
		<div class="container glow-body">
			{/* <div class="logo_header text-center">
				<a href="#"><img src={logo} alt="" /></a>
			</div> */}
			<div class="login_form_outer">
				<div class="login_form">
				<a href="#" className="logo_row"><img src={require("../assets/images/lgo.jpg")} alt="" /></a>
					<h2 className="text-center">Admin Login</h2>
					<div class="form-group">
						<label>Email</label>
						<input type="text"   placeholder="" class="form-control for_username" {...register("email",{required:"Email is required."})} />
					</div>
					<div class="form-group">
						<label>Password</label>
						<input type="password"  placeholder="" class="form-control" {...register('password',{required:"Password is required."})} />
					</div>
					{/* <p class="forgot_password" ><a href="" onClick={forget_password}>Forgot Password?</a></p> */}
					<button class="blue_btn mb-4" onClick={handleSubmit(signin)} >Sign In</button>
				</div>
				{/* <img src="images/shadow.png" alt="" class="shadow" /> */}
			</div>
		</div>
	);
}

export default Login;